import request from "../utils/axios";

export function getSensor(id) {
  return request({
    url: '/api/v1/dashboard/sensor/',
    method: 'GET',
    params: {
      group_id:id,
    }
  })
}
  export function getDevice(id) {
  return request({
    url:'/api/v1/dashboard/device/',
    method: 'GET',
    params: {
      group_id:id,
    }
  })
}

  export  function  getGroup() {
  return request({
    url:'/api/v1/group/',
    method:'GET',
    params:{
      // Content-Type:2,
    }
  })
  }


