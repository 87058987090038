<template>
  <header class="top-header">
    <div class="left">
      <m-select style="float:right;" v-model="group_id" @change=init() class="choose">
        <a-select-option value="">全部</a-select-option>
        <a-select-option v-for="(item, index) in list" :key="index" :value="item.id">{{ item.name }}</a-select-option>
      </m-select>
    </div>
    <div class="middle"><span class="title">生产环境监测</span></div>
    <div class="right">
      <button @click="goGateway"
              style="background: #081419;cursor: pointer;color: #E1EEF2;margin-right: 26px;border:none;text-decoration: none">
        网关管理
      </button>
    </div>
  </header>
</template>

<script>
  import {getGroup} from "../../../api/home";
  export default {
    name: "Top-header",
    props: {},
    created() {
      this.init()
    },
    data() {
      return {
        group_id: '',
        list: []
      }
    },
    methods: {
      goGateway() {
        this.$router.push({
          path: "/gateway",
        })
      },
      init() {
        getGroup().then(res => {
          const result = res.data
          if (result.code === 200) {
            this.list = res.data.data
          }
        })
      },
    },
    watch: {
      group_id: function (newValue, oldValue) {
        this.$bus.$emit('group', newValue)
      }
    }
  };
</script>
<style>
  .choose .ant-select-selection {
    background: #114749;
    border-color: #114749;
    border-radius: 0;
    border-left: 2px solid #66FEFE;
  }
  .choose .ant-select-arrow .ant-select-arrow-icon {
    /*color: blue;*/
    border-color: #114749;
    color: #E1EEF2;
  }

  .a-select-option {
    font-size: 12px;
  }

  .choose-dropdown .ant-select-dropdown-menu-item {
    background: #05181E;
    color: #B4CDD4;

  }

  .ant-select-dropdown-menu {
    padding: 0;
  }

  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #337587;
  }

  li.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
    color: #66FFFF;
  }
</style>


<style scoped>
  .top-header {
    position: relative;
    display: flex;
    height: 62px;
    /*padding: 0;*/
    /*margin: 0;*/
    background-image: url("../../../assets/imgs/daoh_img.png");
    background-size: 100% 100%;
  }

  .left {
    /*color: #E1EEF2;*/
    height: 51px;
    width: 430px;
    display: flex;
    align-items: center;
  }

  .choose {
    width: 176px;
    height: 30px;
    color: #E1EEF2;
    margin-left: 20px;
    border-radius: 0;
    font-size: 12px;
  }

  .middle {
    flex: 1;
    text-align: center;
    line-height: 54px;
  }

  .title {
    color: #E1EEF2;
    font-size: 24px;
    font-weight: bold;
  }

  .right {
    height: 51px;
    width: 430px;
    /*margin: 0px;*/
    /*padding: 0px;*/
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

</style>
