<template>
  <div class="homeleft-width" :key="id" style="display:flex;height: calc(100vh - 98px);flex-direction: column;">
    <div class="home1" style="flex: 1">
      <m-carousel2 class="home" autoplay dotsClass="dot" :autoplaySpeed="10000">
        <div class="item1">
          <div class="wz">设备总览</div>
          <div class="bj">
            <div class="top" style="display: flex">
              <div class="box">
                <div class="topleft">
                  <img src="../../../assets/imgs/chuangq_img@2x.png" alt="">
                </div>
                <div class="topbottom">
                  <div class="sl">{{list.sensor_online}}<span class="zxtai">台</span></div>
                  <div class="ztai">在线</div>
                </div>
                <div class="topright">
                  <div class="sl" style="color: #658C99">{{list.sensor_offline}}<span class="lxtai">台</span>
                  </div>
                  <div class="ztai">离线</div>
                </div>
              </div>
              <div class="style">
                <span class="mchen">传感器</span>
              </div>
            </div>
            <div class="bottom">
              <div class="box">
                <div class="topleft">
                  <img src="../../../assets/imgs/wangg_img@2x.png" alt="">
                </div>
                <div class="topbottom">
                  <div class="sl">{{list.gateway_online}}<span class="zxtai">台</span></div>
                  <div class="ztai">在线</div>
                </div>
                <div class="topright">
                  <div class="sl" style="color: #658C99">{{list.gateway_offline}}<span class="lxtai">台</span>
                  </div>
                  <div class="ztai">离线</div>
                </div>
              </div>
              <div class="style">
                <span class="mchen">网关</span>
              </div>
            </div>
          </div>
        </div>
        <div class="item2">
          <div class="wz">温湿度总览</div>
          <div class="bj">
            <div class="top">
              <div class="left" style="display: flex; align-items: center">
                <div class="fkuang">
                  <div class="wsd">最高温度</div>
                  <div class="margin" style="line-height: 31px; font-size: 20px; color: #FFAF65;font-weight: bold;">
                    <span class="szhi">{{list.stats_data.max_temp?list.stats_data.max_temp.val:'-'}}</span>
                    <span class="bdfhao">℃</span>
                  </div>
                  <div class="name" :title="list.stats_data.max_temp?list.stats_data.max_temp.name:'暂无设备'">
                    {{list.stats_data.max_temp?list.stats_data.max_temp.name:'暂无设备'}}
                  </div>
                </div>
              </div>
              <div class="right" style="display: flex; align-items: center">
                <div class="fkuang">
                  <div class="wsd">最低温度</div>
                  <div class="margin" style="line-height: 31px; font-size: 20px; color: #4BB7FF;font-weight: bold">
                    <span class="szhi">{{list.stats_data.min_temp?list.stats_data.min_temp.val:'-'}}</span>
                    <span class="bdfhao">℃</span>
                  </div>
                  <div class="name" :title="list.stats_data.min_temp?list.stats_data.min_temp.name:'暂无设备'">
                    {{list.stats_data.min_temp?list.stats_data.min_temp.name:'暂无设备'}}
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="left" style="display: flex; align-items: center">
                <div class="fkuang">
                  <div class="wsd">最高湿度</div>
                  <div class="margin" style="line-height: 31px; font-size: 20px;color: #FFAF65;font-weight: bold">
                    <span class="szhi">{{list.stats_data.max_hum?list.stats_data.max_hum.val:'-'}}</span>
                    <span class="bdfhao">%</span>
                  </div>
                  <div class="name" :title="list.stats_data.max_hum?list.stats_data.max_hum.name:'暂无设备'">
                    {{list.stats_data.max_hum?list.stats_data.max_hum.name:'暂无设备'}}
                  </div>
                </div>
              </div>
              <div class="right" style="display: flex; align-items: center">
                <div class="fkuang">
                  <div class="wsd">最低湿度</div>
                  <div class="margin" style="line-height: 31px; font-size: 20px; color: #4BB7FF;font-weight: bold">
                    <span class="szhi">{{list.stats_data.min_hum?list.stats_data.min_hum.val:'-'}}</span>
                    <span class="bdfhao">%</span>
                  </div>
                  <div class="name" :title="list.stats_data.min_hum?list.stats_data.min_hum.name:'暂无设备'">
                    {{list.stats_data.min_hum?list.stats_data.min_hum.name:'暂无设备'}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item3">
          <div class="wz">微信接收告警</div>
          <div class="bj">
            <div class="top">
              <img class="top-img" src="../../../assets/imgs/erwm_img@2x.png" alt="">
            </div>
            <div class="bottom">
              <span>1. 微信扫码关注<span style="color: #66FFFF">“宇泰高科”</span>服务号</span>
              <span>2. 在服务号发送<span style="color: #66FFFF">“接收告警”</span>即可订阅告警</span>
              <span>3. 在服务号发送<span style="color: #66FFFF">“退订告警”</span>即可退订告警</span>
            </div>
          </div>
        </div>
      </m-carousel2>
    </div>
    <div class="home2">
      <div class="top">温湿度与你的健康</div>
      <m-carousel autoplay :autoplaySpeed="10000" dotsClass="dot">
        <div class="item4">
          <div style="margin-bottom: 12px">
            <img src="../../../assets/imgs/wensd_icon.png" alt="">
          </div>
          <span class="bti">舒适的温湿度值</span>
          <ul style="font-size: 14px;color:#B4CDD4;padding-left: 20px;line-height: 36px">
            <li>夏天温度<b>23~28℃</b>，湿度<b>30~60%</b></li>
            <li>冬天温度<b>18~25℃</b>，湿度<b>30~80%</b></li>
            <li>在这种范围内感到舒适的人占<b>95%</b>以上</li>
          </ul>
        </div>
        <div class="item5">
          <div style="margin-bottom: 12px">
            <img src="../../../assets/imgs/siwe_icon.png" alt="">
          </div>
          <span class="bti">温湿度对人思维活动的影响</span>
          <span class="dluo">脑力劳动者，最适合的室内温度是<b>25℃</b>,湿度为<b>40%--60%</b>。此时人的精神状态极佳，工作效率高，考虑问题思维敏捷，能够触类旁通举一反三</span>
        </div>
        <div class="item6">
          <div style="margin-bottom: 12px">
            <img src="../../../assets/imgs/bingd_icon.png" alt="">
          </div>
          <span class="bti">湿度对螨虫和霉菌的影响</span>
          <span class="dluo">在湿度低于<b>55%</b>的时候,螨虫和霉菌难以生存，此环境下的螨虫会吸收体内的水分，并在<b>10天</b>内脱水死亡</span>
        </div>
        <div class="item7">
          <div style="margin-bottom: 12px">
            <img src="../../../assets/imgs/weih_icon.png" alt="">
          </div>
          <span class="bti">高湿度的危害</span>
          <span style="font-size: 14px;color:#B4CDD4;line-height: 24px;letter-spacing: 1px">目前，全世界已公认室内的<b>湿气是危害人体健康的一个重要因素</b><br>在湿气重的房间里居住会提高发病率<b>在高湿度的房间里经常出现的疾病主要有过敏和炎症</b><br>潮湿房间居住患哮喘病的风险大大提高</span>
        </div>
      </m-carousel>
    </div>
  </div>
</template>
<script>
  import {getDevice} from "../../../api/home";

  export default {
    name: "home",
    created() {
      this.init()
    },
    mounted() {
      this.$bus.$on("group", (id) => {
        this.id = id
        this.getData(id)
      })
    },
    destroyed() {
      this.$bus.$off('group')
    },
    data() {
      return {
        list: {
          stats_data: {},
        },
        id: '',
      }
    },
    methods: {
      init() {
        this.getData(this.id)
      },
      getData(id) {
        getDevice(id).then(res => {

          const result = res.data
          if (result.code === 200) {
            this.list = result.data
          }
        }).catch(err => {
        })
      }
    },
  }
</script>
<style scoped lang="less">
  .item1 {
    height: 100%;
    display: flex !important;
    flex-direction: column;

    .wz {
      color: white;
      line-height: 22px;
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: bold;
    }

    .bj {
      height: 100%;
      display: flex !important;
      flex-direction: column;
      padding: 16px;
      background: #0C2F33;
      border-radius: 8px;
    }

    .top {
      display: flex;
      flex: 1;
      border-radius: 8px;
      border: 1px solid #337587;
      margin-bottom: 16px;
      position: relative;
    }

    .bottom {
      display: flex;
      flex: 1;
      border-radius: 8px;
      border: 1px solid #337587;
      position: relative;
    }

    .box {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 80px;
      display: flex;
      align-items: center;
    }

    .topleft {
      text-align: center;
      font-size: 12px;
      color: #658C99;

      img {
        height: 72px;
        width: 72px;
      }

      span {
        font-size: 12px;
        color: #658C99;
      }
    }

    .topbottom {
      flex: 1;
      display: flex;
      flex-direction: column;

      .sl {
        flex: 1;
        color: #66FFFF;
        font-weight: bold;
      }
    }

    .topright {
      display: flex;
      flex-direction: column;
      flex: 1;

      .sl {
        flex: 1;
        color: #66FFFF;
        font-weight: bold;
      }
    }
  }

  .item2 {
    height: 100%;
    display: flex !important;
    flex-direction: column;

    .wz {
      color: white;
      line-height: 22px;
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: bold;
    }

    .bj {
      height: 100%;
      display: flex !important;
      flex-direction: column;
      padding: 16px;
      background: #0C2F33;
      border-radius: 8px;
    }

    .top {
      flex: 1;
      margin-bottom: 16px;
      display: flex;
      position: relative;
    }

    .bottom {
      flex: 1;
      display: flex;
    }

    .left {
      flex: 1;
      width: 0;
      margin-right: 16px;
      border: 1px solid #337587;
      border-radius: 8px;
      text-align: center;
      padding: 10px;
    }

    .right {
      flex: 1;
      width: 0;
      border: 1px solid #337587;
      border-radius: 8px;
      position: relative;
      text-align: center;
      padding: 10px;
    }
  }

  .item3 {
    height: 100%;
    display: flex !important;
    flex-direction: column;

    .wz {
      color: white;
      line-height: 22px;
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: bold;
    }

    .bj {
      height: 100%;
      display: flex !important;
      flex-direction: column;
      padding: 16px;
      background: #0C2F33;
      border-radius: 8px;
    }

    .top {
      /*height: 100%;*/
      flex: 1;
      /*background: green;*/
      margin-bottom: 15px;
      display: flex;
      position: relative;
      justify-content: center
    }

    .top-img {
      /*flex: 1;*/
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }

    .bottom {
      flex: 1;
      /*background: #4BB7FF;*/
      display: flex;
      flex-direction: column;
    }

    span {
      font-size: 14px;
      color: #E1EEF2;
    }

    span + span {
      margin-top: 12px;
    }
  }

  .item4 {
    height: 100%;
    display: flex !important;
    flex-direction: column;
    /*padding: 16px;*/
    background: #0C2F33;
    border-radius: 8px;

    b {
      color: #66FEFE;
    }

  }

  .item5 {
    height: 100%;
    display: flex !important;
    flex-direction: column;
    padding: 16px;
    background: #0C2F33;
    border-radius: 8px;

    b {
      color: #66FEFE;
    }
  }

  .item6 {
    height: 100%;
    display: flex !important;
    flex-direction: column;
    padding: 16px;
    background: #0C2F33;
    border-radius: 8px;

    b {
      color: #66FEFE;
    }
  }

  .item7 {
    height: 100%;
    display: flex !important;
    flex-direction: column;
    padding: 16px;
    background: #0C2F33;
    border-radius: 8px;

    b {
      color: #66FEFE;
    }
  }

</style>
<style lang="less" scoped>
  .homeleft-width {
    display: flex;
    height: calc(100vh - 98px);
    flex-direction: column;

  }

  .home1 + .home2 {
    margin-top: 18px;
  }

  @media (min-width: 1550px) {
    .homeleft-width {
      min-height: 798px;
    }

    .home1 {
      min-height: 390px;
    }

    .home2 {
      min-height: 390px;
    }
  }

  @media (max-width: 1550px) {
    .homeleft-width {
      min-height: 670px;
    }

    .home1 {
      min-height: 306px;
    }

    .home2 {
      min-height: 306px;
    }
  }

  .home1 {
    z-index: 0;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 16px;


    &:after {
      display: block;
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-top: 40px solid transparent;
      border-bottom: 40px solid transparent;
      border-left: 40px solid transparent;
      border-right: 40px solid transparent;
      border-image: url("~@/assets/imgs/dik_tr_img.png") 40 40 40 40 fill;
    }
  }

  .home2 {
    z-index: 0;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 16px;
    /*min-height: 306px;*/

    &:after {
      display: block;
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-top: 40px solid transparent;
      border-bottom: 40px solid transparent;
      border-left: 40px solid transparent;
      border-right: 40px solid transparent;
      border-image: url("~@/assets/imgs/dik_tr_img.png") 40 40 40 40 fill;
    }
  }

  .top {
    color: white;
    line-height: 22px;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: bold;
  }

  .item {
    height: 100%;
    background-color: lightseagreen;
  }

  .margin {
    line-height: 31px;
    font-size: 20px;
    color: #FFAF65;
    font-weight: bold;
    margin: 8px 0;
  }

  .style {
    width: 44px;
    height: 22px;
    background: rgba(102, 255, 255, 0.1);
    border-radius: 0px 7px 0px 8px;
    text-align: center;
    line-height: 20px;
    position: absolute;
    margin-left: auto;
    top: 0;
    right: 0
  }

  .ztai {
    font-size: 12px;
    color: #658C99
  }

  .zxtai {
    font-size: 14px;
    color: #66FFFF
  }

  .lxtai {
    font-size: 14px;
    color: #658C99
  }

  .mchen {
    color: #658C99;
    font-size: 12px;
    font-weight: 400
  }

  .fkuang {
    height: 80px;
    width: 100%;
    text-align: center;
  }

  .bdfhao {
    font-size: 14px
  }

  .wsd {
    line-height: 16px;
    font-size: 12px;
    color: #658C99;
    display: flex;
    flex-direction: column;
  }

  .szhi {
    padding-right: 2px
  }

  .news {
    display: flex;
    flex: 1;
  }

  .name {
    line-height: 16px;
    font-size: 12px;
    color: #B4CDD4;


    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  .dluo {
    font-size: 14px;
    color: #B4CDD4;
    line-height: 36px;
  }

  .bti {
    font-weight: bold;
    color: #E1EEF2;
    font-size: 16px;
    margin-bottom: 16px
  }

  @media (min-width: 1550px) {
    .item1 {
      img {
        margin: auto 40px auto 40px;
      }

    }
  }

  @media (max-width: 1550px) {
    .item1 {
      img {
        margin: auto 20px auto 20px;
      }

    }
  }

  @media (max-width: 1550px) {
    .margin {
      margin: 4px 0;
    }
  }

  @media (min-width: 1550px) {
    .margin {
      margin: 8px 0;
    }
  }

  @media (max-width: 1550px) {
    .homeleft-width {
      width: 346px;
    }
  }

  @media (min-width: 1550px) {
    .homeleft-width {
      width: 480px;
    }
  }

  @media (min-width: 1550px) {
    .item4 {
      padding: 32px 32px 24px 32px;
    }
  }

  @media (max-width: 1550px) {
    .item4 {
      padding: 16px;
    }
  }

  @media (min-width: 1550px) {
    .item5 {
      padding: 32px 32px 24px 32px;
    }
  }

  @media (max-width: 1550px) {
    .item5 {
      padding: 16px;
    }
  }

  @media (min-width: 1550px) {
    .item6 {
      padding: 32px 32px 24px 32px;
    }
  }

  @media (max-width: 1550px) {
    .item6 {
      padding: 16px;
    }
  }

  @media (min-width: 1550px) {
    .item7 {
      padding: 32px 32px 24px 32px;
    }
  }

  @media (max-width: 1550px) {
    .item7 {
      padding: 16px;
    }
  }

  @media (max-width: 1700px) {
    .sl {
      font-size: 20px;
    }
  }

  @media (min-width: 1700px) {
    .sl {
      /*font-size: 26px;*/
      font-size: 36px;
    }
  }

  @media (max-width: 1700px) {
    .szhi {
      font-size: 24px;
    }
  }

  @media (min-width: 1700px) {
    .szhi {
      /*font-size: 30px;*/
      font-size: 36px;
    }
  }
</style>


