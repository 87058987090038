<template>
  <div class="home">
    <top-header value="1232"></top-header>
    <home-bottom></home-bottom>
    <router-view></router-view>
  </div>
</template>

<script>
  import TopHeader from "./component/TopHeader";
  import HomeBottom from "./component/HomeBottom";

  export default {
    name: "index",
    components: {
      TopHeader,
      HomeBottom,
    },
  };
</script>
<style scoped>
  .home {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
</style>