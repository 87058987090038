import axios from 'axios'

import router from "../router";

const request = axios.create({
  baseURL: window.location.origin,
  timeout: 5000,
})


// 拦截器 [请求拦截器和响应拦截器]

// 请求拦截器
// request.interceptors.request.use(config => {
//
//   let token = sessionStorage.getItem("token")
//   if(token){
//     config.headers['token'] = token
//   }else{
//     config.headers['token'] = "fake token"
//   }
//
//   // 需要将config重新return出去，否则不会发送请求
//   return config
//
//   // 很少情况会进入这个函数
// },error => {
//   console.log('error', error)
//   Promise.reject(error)
// })
//
//
// // 响应拦截器
// request.interceptors.response.use(response => {
//   console.log(response)
//
//   // if(response.status === 401){
//   //   router.push('/login')
//   // }
//
//   return response.data
//
//   // 请求失败会进入这个回调函数
// }, error => {
//   console.log(error)
//   Promise.reject("请求失败了")
// })

export default request

