<template>
  <div class="home-right" :key="id">
    <template v-if="list.length === 1">
      <div class="carousel-item">
        <div class="home">
          <div class="top">
            <data-item v-for="item in list[0].slice(0,5)" class="item" :item="item" :group="id"></data-item>
          </div>
          <div class="bottom">
            <data-item v-for="item in list[0].slice(5)" class="item" :item="item" :group="id">
            </data-item>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="list.length > 1">
      <m-carousel1 vertical fade :inifinite="false" autoplay :autoplaySpeed="30000">
        <div class="carousel-item" v-for="temp in list">
          <div class="home">
            <div class="top">
              <data-item v-for="item in temp.slice(0,5)" class="item" :item="item" :group="id"></data-item>
            </div>
            <div class="bottom">
              <data-item v-for="item in temp.slice(5)" class="item" :item="item" :group="id">
              </data-item>
            </div>
          </div>
        </div>
      </m-carousel1>
    </template>
    <empty-content v-else>
      <template slot="img">
        <img class="listImage" alt="text" src="../../../assets/imgs/wusj_img.png"/>
      </template>
      <template slot="desc">
        <span>暂无数据</span>
      </template>
    </empty-content>
  </div>
</template>
<script>
  import {getSensor} from "@/api/home";
  import DataItem from "./DataItem";

  export default {
    name: "User",
    components: {DataItem},
    created() {
      this.init()
    },
    mounted() {
      this.$bus.$on("group", (id) => {
        this.id = id
        this.getData(id)
      })
    },
    destroyed() {
      this.$bus.$off('group')
    },
    data() {
      return {
        list: [],
        id: '',
      }
    },
    methods: {
      init() {
        this.getData(this.id)
      },
      getData(id) {
        getSensor(id).then(res => {
          const result = res.data
          if (result.code === 200) {
            const sortData = this.sortArray(result.data)
            this.list = this.transformData(sortData)
            this.$forceUpdate()
          }
        }).catch(err => {
        })
      },
      transformData(list) {
        let pages = Math.ceil(list.length / 10);
        let res = [];
        let offset = 0;
        let limit = 10
        for (let i = 0; i < pages; i++) {
          res.push(list.slice(offset, offset + limit))
          offset += 10
        }
        return res
      },
      sortArray(array) {
        //遍历完state为1的值放在online中
        let online = []
        //遍历完state为1的值放在deadline中
        let deadline = []
        //遍历每个数组为1的值放在online中
        array.forEach(item => {
          if (item.state === 1) {
            online.push(item)
            //遍历每个数组为0的值放在deadline中
          } else if (item.state === 0) {
            deadline.push(item)
          }
        })
        //把online和deadline两个数组得到的数据全部再return出去
        //["...online":指拓展online中的所有值（代表全部online值）]
        //["...deadline":指拓展deadline中的所有值（代表全部deadline值）]
        return [...online, ...deadline]
      }
    }
  }
</script>
<style scoped>
  @media (min-width: 1550px) {
    .home-right {
      min-height: 798px;
    }

    .carousel-item {
      min-height: 798px;
    }
  }

  @media (max-width: 1550px) {
    .home-right {
      min-height: 670px;
    }

    .carousel-item {
      min-height: 670px;
    }
  }

  .home-right {
    display: flex;
    flex-direction: column;
    margin-left: 18px;

  }

  .carousel-item {
    height: calc(100vh - 98px);

  }

  .home {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .top {
    flex: 1;
    margin-bottom: 18px;
    display: flex;
    margin-right: -9px;
    margin-left: -9px;
  }

  .bottom {
    flex: 1;
    display: flex;
    margin-right: -9px;
    margin-left: -9px;
  }

  .item {
    flex: 0 0 20%;
    padding: 0 9px;
  }

  .listImage {
    width: 180px;
    height: 180px;
    fill: #658C99;
    overflow: hidden;
  }
</style>