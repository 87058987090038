<template>
  <div class="data-item">
    <div class="content">
      <div class="bj" v-if="item.state === 1">
        <button @click="goGateway" class="button">
          <span class="bt" :title="item.name">{{item.name}}</span>
          <img src="../../../assets/imgs/xiangq_icon.png" class="img">
        </button>
        <div class="zsd">
          <div class="light"></div>
          <div style=" margin: auto 0px;flex: 1">
            <template v-if="( item.hum > 40 && item.hum <= 70) && (item.temp > 19 && item.temp <= 28)">
              <div class="zt3">
                <div class="sshi">舒适</div>
              </div>
            </template>
            <template v-else-if="(item.hum > 70  || item.hum <= 40) && (item.temp > 19 && item.temp <= 28)">
              <div class="zt3">
                <div style="background: #4BB7FF" class="yshi" v-if="item.hum > 70">潮湿</div>
                <div style="background: #FFAF65" class="yshi" v-else-if="item.hum <= 40">干燥</div>
              </div>
            </template>
            <template
              v-else-if="((item.temp > 40 || item.temp <= 19) || (item.temp > 28 && item.temp <= 40)) && (item.hum > 40 && item.hum <= 70)">
              <div class="zt3">
                <div style="background: #FFAF65" class="yshi" v-if=" item.temp > 28 && item.temp <= 40">炎热</div>
                <div style="background: #4BB7FF" class="yshi" v-else-if="item.temp  <= 19">寒冷</div>
                <div style="background: red" class="yshi" v-else-if="item.temp > 40">高温</div>
              </div>
            </template>
            <template v-else>
              <div class="zt2">
                <div style="background: #4BB7FF" class="yshi" v-if="item.hum > 70">潮湿</div>
                <div style="background: #FFAF65" class="yshi" v-else-if="item.hum <= 40">干燥</div>
              </div>
              <div class="zt1">
                <div style="background: #FFAF65" class="yshi" v-if="item.temp > 28 && item.temp <= 40">炎热</div>
                <div style="background: #4BB7FF" class="yshi" v-else-if="item.temp <= 19">寒冷</div>
                <div style="background: red" class="yshi" v-else-if="item.temp > 40">高温</div>
              </div>
            </template>
          </div>
        </div>
        <div class="wendu" style="display: flex; align-items: center">
          <div class="fkuang">
            <div class="wzhi">温度</div>
            <div class="margin" style="line-height: 31px; font-size: 24px;font-weight: bold">
              <span style="color:#FFAF65" class="dshu" v-if="item.temp > 28 && item.temp <= 40">{{item.temp}}</span>
              <span style="color:#66FFFF" class="dshu"
                    v-else-if="item.temp <= 28 && item.temp > 19">{{item.temp}}</span>
              <span style="color:#4BB7FF" class="dshu" v-else-if="item.temp <= 19">{{item.temp}}</span>
              <span style="color:red" class="dshu" v-else-if="item.temp > 40">{{item.temp}}</span>
              <span style="color:#FFAF65" class="fhao" v-if="item.temp > 28 && item.temp <= 40">℃</span>
              <span style="color:#66FFFF" class="fhao" v-else-if="item.temp <= 28 && item.temp > 19">℃</span>
              <span style="color:#4BB7FF" class="fhao" v-else-if="item.temp <= 19">℃</span>
              <span style="color:red" class="fhao" v-else-if="item.temp > 40">℃</span>
            </div>
            <div class="zshi">最高{{item.temp_max}}℃ 最低{{item.temp_min}}℃</div>
          </div>
        </div>
        <div class="shidu" style="display: flex; align-items: center">
          <div class="fkuang">
            <div class="wzhi">湿度</div>
            <div class="margin" style="line-height: 31px; font-size: 24px;font-weight: bold">
              <span style="color:#4BB7FF" class="dshu" v-if="item.hum > 70">{{item.hum}}</span>
              <span style="color:#66FFFF" class="dshu" v-else-if="item.hum <= 70 && item.hum > 40">{{item.hum}}</span>
              <span style="color:#FFAF65" class="dshu" v-else-if="item.hum <= 40">{{item.hum}}</span>
              <span style="color:#4BB7FF" class="fhao" v-if="item.hum > 70">%</span>
              <span style="color:#66FFFF" class="fhao" v-else-if="item.hum <= 70 && item.hum > 40">%</span>
              <span style="color:#FFAF65" class="fhao" v-else-if="item.hum <= 40">%</span>
            </div>
            <div class="zshi">最高{{item.hum_max}}% 最低{{item.hum_min}}%</div>
          </div>
        </div>
      </div>
      <div class="bj" v-else>
        <button @click="goGateway" class="button">
          <span class="bt" :title="item.name">{{item.name}}</span>
          <img src="../../../assets/imgs/xiangq_icon.png" class="img">
        </button>
        <div class="zsd">
          <div class="light2"></div>
          <div style=" margin: auto 0px;flex: 1">
            <div class="zt1"
                 style=" height: 18px; width: 56px;color: #020511;font-size: 12px; text-align: center;border-radius: 2px">
              <div class="lx">设备离线</div>
            </div>
          </div>
        </div>
        <div class="wendu" style="display: flex; align-items: center">
          <div class="lxfkuang">
            <div class="lxwzhi">温度</div>
            <div class="margin" style="line-height: 31px; font-size: 24px;font-weight: bold;color: #658C99">
              <span class="lxdshu">-</span>
              <span style="font-size: 14px">℃</span>
            </div>
            <div class="lxzshi">最高-℃ 最低-℃</div>
          </div>
        </div>
        <div class="shidu" style="display: flex; align-items: center">
          <div class="lxfkuang">
            <div class="lxwzhi">湿度</div>
            <div class="margin" style="line-height: 31px; font-size: 24px;font-weight: bold;color: #658C99">
              <span class="lxdshu">-</span>
              <span style="font-size: 14px">%</span>
            </div>
            <div class="lxzshi">最高-% 最低-%</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {getDevice} from "../../../api/home";

  export default {
    name: "DataItem",
    props: ['item', 'group'],
    data() {
      return {
        group_id: ''
      }
    },
    created() {
      this.init()
    },
    mounted() {
    },
    destroyed() {
    },
    methods: {
      init() {
      },
      getData(id) {
        console.log('getDevice')
        getDevice(id).then(res => {
          // console.log('res', res)
          const result = res.data
          if (result.code === 200) {
            this.list = result.data
          }
        })
      },
      goGateway() {
        setTimeout(() => {
          this.$router.push({
            path: "/gateway",
            query: {id: this.item.id, gateway_id: this.item.gateway_id, group: this.group}
          })
        }, 100)
      },
    }
  }
</script>
<style lang="less" scoped>
  @media (min-width: 1550px) {
    .data-item {
      min-height: 390px;
    }
  }

  @media (max-width: 1550px) {
    .data-item {
      min-height: 306px;
    }
  }

  .data-item {
    z-index: 0;
    position: relative;
  }

  .content {
    z-index: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .bj {
    position: relative;
    z-index: 0;
    padding: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 326px;

    &:after {
      display: block;
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-top: 40px solid transparent;
      border-bottom: 40px solid transparent;
      border-left: 40px solid transparent;
      border-right: 40px solid transparent;
      border-image: url("~@/assets/imgs/dik_trx_img.png") 40 40 40 40 fill;
      min-height: 326px;
    }
  }

  .button {
    display: flex;
    background: none;
    text-align: left;
    border: none;
    text-decoration: none;
    cursor: pointer;
    padding: 0;
  }

  .button:active {
    outline: none;
  }

  .bt {
    color: #E1EEF2;
    font-size: 16px;
    font-weight: bold;
    flex: 7;
    width: 0;
    margin-bottom: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  .img {
    height: 14px;
    width: 14px;
    margin-top: 5px;
  }

  .zsd {
    flex: 13;
    margin-bottom: 8px;
    background: #0C2F33;
    border-radius: 8px;
    align-items: center;
    display: flex;
    padding: 10px 12px;
  }

  .light {
    width: 12px;
    height: 12px;
    background-color: #6CE177;
    border-radius: 50%;
    opacity: 0.4;

    animation-name: breath;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    box-shadow: 0 0 12px #6CE177;
  }

  @keyframes breath {
    from {
      opacity: 0.4;
    }
    50% {
      opacity: 1;
    }
    to {
      opacity: 0.4;
    }
  }

  .light2 {
    border-radius: 50%;
    height: 12px;
    width: 12px;
    background: #658C99;
    box-shadow: 0px 0px 12px #658C99;
  }

  .zt2 {
    height: 18px;
    width: 30px;
    color: #020511;
    font-size: 12px;
    text-align: center;
    font-family: HarmonyOS Sans SC;
    float: right;
    border-radius: 2px
  }

  .zt1 {
    height: 18px;
    width: 30px;
    color: #020511;
    font-size: 12px;
    text-align: center;
    font-family: HarmonyOS Sans SC;
    float: right;
    border-radius: 2px
  }

  .zt3 {
    height: 18px;
    width: 30px;
    /*background: #FFAF65;*/
    color: #020511;
    font-size: 12px;
    text-align: center;
    font-family: HarmonyOS Sans SC;
    float: right;
    border-radius: 2px
  }

  .zt2 + .zt1 {
    margin-right: 8px;
  }

  .sshi {
    height: 100%;
    background: #66FFFF;
    border-radius: 2px
  }

  .wzhi {
    line-height: 16px;
    font-size: 12px;
    color: #658C99
  }

  .wendu {
    flex: 35;
    margin-bottom: 8px;
    background: #0C2F33;
    border-radius: 8px;
    text-align: center;
  }

  .zshi {
    line-height: 16px;
    font-size: 12px;
    color: #B4CDD4
  }

  .margin {
    margin: 8px 0;
  }

  .fkuang {
    line-height: 103px;
    width: 100%;
    text-align: center
  }

  .yshi {
    border-radius: 2px;
    height: 100%;
  }

  .dshu {
    padding-right: 2px;
  }

  .lx {
    height: 100%;
    background: #658C99;
    border-radius: 2px;
  }

  .lxfkuang {
    line-height: 103px;
    width: 100%;
    text-align: center
  }

  .lxwzhi {
    line-height: 16px;
    font-size: 12px;
    color: #658C99
  }

  .lxdshu {
    font-size: 24px;
    padding-right: 2px
  }

  .lxzshi {
    line-height: 16px;
    font-size: 12px;
    color: #658C99
  }

  @media (max-width: 1400px) {
    .margin {
      margin: 4px 0;
    }
  }

  @media (min-width: 1400px) {
    .margin {
      margin: 8px 0;
    }
  }

  @media (max-width: 1700px) {
    .dshu {
      font-size: 24px;
    }
  }

  @media (min-width: 1700px) {
    .dshu {
      /*font-size:30px;*/
      font-size: 36px;
    }
  }

  @media (max-width: 1700px) {
    .fhao {
      font-size: 14px;
    }
  }

  @media (min-width: 1700px) {
    .fhao {
      font-size: 18px;
    }
  }

  .shidu {
    flex: 35;
    background: #0C2F33;
    border-radius: 8px;
  }

  .yellow {
    background: #FFAF65;
  }

  .greed {
    background: green;
  }

  .blue {
    background: #4BB7FF;
  }
</style>
