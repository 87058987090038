<template>
  <div class="home-bottom">
    <home-left class="home-left"></home-left>
    <home-right class="home-right"></home-right>
  </div>
</template>

<script>
  import HomeLeft from "../component/HomeLeft";
  import HomeRight from "../component/HomeRight";

  export default {
    name: "HomeBottom",
    components: {
      HomeLeft,
      HomeRight
    },
  }
</script>

<style scoped>
  .home-bottom {
    flex: 1;
    display: flex;
    margin: 16px 35px 20px 20px;
    height: 100%;
  }

  .home-right {
    flex: 1;
  }
</style>